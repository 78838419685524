$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(".js-nav").addClass("active");
    });

    $("a[data-type='resource']").click(function() {
        $(this).parents(".js-categorycontent").removeClass("active");
    });

    $(".js-nav-item-trigger-infos").click(function(){
        $('[data-category="infos"]').trigger("click");
    });

    $(".js-nav-item").click(function(){
        var category = $(this).data("category");

        $(".js-nav").addClass("active");
        $(this).parent().addClass("active").siblings().removeClass("active");

        $(".js-categorycontent").addClass("active");
        $(".js-categorycontent-block").hide();
        $(".js-categorycontent-block#category-" + category).fadeIn();
    });

    $(".js-category-button").click(function(){
        $(".js-nav").removeClass("active");
        $(".js-categorycontent").removeClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentArea
    // *
    // *
    $(window).on('hashchange', function(e) {
        showFilterContent();
    });

    if (window.location.hash) {
        showFilterContent();
    }

    function showFilterContent() {
        var hash = window.location.hash.split('#')[1];

        $(".js-contentarea-left").scrollTop(0);
        $(".js-intro").addClass("noAnimation");
        $(".js-nav").removeClass("active");
        $(".js-contentarea").addClass("active");
        $('.js-question-filter[data-filter=' + hash + ']').parent().addClass("active").siblings().removeClass("active");

        filterQuestions(hash);
    }

    function filterQuestions(filter) {
        $(".js-question").hide();
        $(".js-question.js-filter-" + filter).show();
    }

    // question select
    $(".js-question").click(function(){
        var id = $(this).data("question");

        $(this).addClass("active").siblings().removeClass("active");

        // show answer content
        $(".js-contentarea-right").addClass("active").scrollTop(0);
        $(".js-answer").hide();
        $('.js-answer[data-answer="' + id + '"]').show();
        $(".js-answer").find(".js-tab-navitem:first-child").trigger("click");

        // add class to wrapper for mobile hide
        $(".js-contentarea-left").addClass("hide");
    });
    
    // remove hide to get back to questions
    $(".js-contentarea-left-toggle").click(function(){
        $(".js-contentarea-left").removeClass("hide");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tabContent
    // *
    // *
    $(".js-tab-navitem").click(function() {
        var index = $(this).index();

        $(".js-contentarea-right").scrollTop(0);
        $(this).addClass("active").siblings().removeClass("active");

        $(this).parents(".js-answer").find(".js-tab-content:eq(" + index + ")").addClass("active").siblings().removeClass("active");
    });

    // hide tab nav if only a single child
    $(".js-tab-wrapper").each(function(){
        if ($(this).find("*").length <= 1) {
            $(this).hide();
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * footer
    // *
    // *
    $(".js-footer-button").click(function(){
        $(".js-footer").toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cursor follows mouse
    // *
    // *
    $('.js-cursor-wrapper').each(function() {
        $(this).on('mousemove', function(e) {
            var cursorHeight = $(this).find('.js-cursor').outerHeight();
            var cursorWidth = $(this).find('.js-cursor').outerWidth()/2;
            $(this).find('.js-cursor').offset({left: e.pageX - cursorWidth, top: e.pageY - cursorHeight});
        });
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * start animation
    // *
    // *
    $(".js-startanimation-button").click(function(){
        $(this).parents(".js-startanimation-sibling").next(".js-startanimation").addClass("animate");
    });

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
});




